




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import FEATURES from '@/modules/common/constants/features.constant';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

@Component({
    components: {
        DateDocumentFilter,
    },
})
export default class EventsHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;

    get isChainOrCluser() {
        return this.userService.isChainUser || this.userService.isClusterUser;
    }

    get name() {
        return this.userService.isViewAsChain || this.userService.isViewAsCluster
            ? this.userService.levelName
            : this.hotelName;
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get chainName() {
        return this.userService.user ? this.userService.user.chainName : null;
    }

    get feature() {
        return FEATURES.EVENTS;
    }
}
