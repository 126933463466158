var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-calendar"},[(_vm.userService.isCarUser)?_c('CustomLoader',{attrs:{"isLoading":_vm.skeleton,"useOldLoader":true,"isShownOnMount":true}}):_vm._e(),(!_vm.skeleton && _vm.year !== null && _vm.month !== null)?_c('Calendar',{attrs:{"year":_vm.year,"month":_vm.month,"borders":true},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var day = ref.day;
return [(!_vm.eventsManagerService.storeState.loading.isLoading())?_c('div',{staticClass:"calendar-item",on:{"click":function($event){return _vm.handleCalendarItemClick(day)}}},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day",class:{'calendar-item__day_current': _vm.documentFiltersService.isCurrentDay(day)}},[_vm._v(_vm._s(day))])]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l(((_vm.getDayEvents(day)).slice(0, 2)),function(dayEvent){return _c('li',{key:dayEvent.event.id,on:{"click":function (e) { return _vm.viewEvent(e, dayEvent.event.id); }}},[(dayEvent.isHoliday)?_c('EventsCard',{attrs:{"is-holiday":true,"is-short":true,"event":dayEvent.event}}):_vm._e(),(dayEvent.isMy)?_c('EventsCard',{attrs:{"is-my":true,"is-short":true,"event":dayEvent.event}}):_vm._e(),(dayEvent.isSuggested)?_c('EventsCard',{attrs:{"is-suggested":true,"is-short":true,"event":dayEvent.event}}):_vm._e(),(dayEvent.isChain)?_c('EventsCard',{attrs:{"is-my":true,"is-short":true,"event":dayEvent.event}}):_vm._e()],1)}),0)]),(_vm.hiddenEvents(day))?_c('div',{staticClass:"calendar-item__footer"},[_vm._v(" +"+_vm._s(_vm.hiddenEvents(day))+" more.. ")]):_vm._e()]):_vm._e()]}},{key:"day-outside-month",fn:function(ref){
var day = ref.day;
return [_c('div',{staticClass:"calendar-item calendar-item__last-previous"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day calendar-item__day_empty"},[_vm._v(_vm._s(day))])])])]}}],null,false,2798961261)}):(!_vm.userService.isCarUser)?_c('Calendar',{staticClass:"events-calendar--skeleton skeleton-container",attrs:{"year":_vm.year,"month":_vm.month,"borders":""},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var day = ref.day;
return [_c('div',{staticClass:"calendar-item"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day skeleton--simple",class:{ skeleton: _vm.skeleton }},[_vm._v(_vm._s(day))])]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l((2),function(d){return _c('li',{key:d + 'skeleton'},[_c('div',{staticClass:"skeleton skeleton--simple",domProps:{"innerHTML":_vm._s(_vm.helperService.fill(27))}})])}),0)]),_c('div',{staticClass:"calendar-item__footer"},[_c('span',{staticClass:"skeleton skeleton--simple",domProps:{"innerHTML":_vm._s(_vm.helperService.fill(17))}})])])]}},{key:"day-outside-month",fn:function(ref){
var day = ref.day;
return [_c('div',{staticClass:"calendar-item"},[_c('div',{staticClass:"calendar-item__header"},[_c('span',{staticClass:"calendar-item__day",class:{ skeleton: _vm.skeleton }},[_vm._v(_vm._s(day))])]),_c('div',{staticClass:"calendar-item__body"},[_c('ul',{staticClass:"calendar-item__events-list"},_vm._l((2),function(d){return _c('li',{key:d + 'skeleton'},[_c('div',{staticClass:"skeleton",domProps:{"innerHTML":_vm._s(_vm.helperService.fill(27))}})])}),0)]),_c('div',{staticClass:"calendar-item__footer"},[_c('span',{staticClass:"skeleton",domProps:{"innerHTML":_vm._s(_vm.helperService.fill(17))}})])])]}}])}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }