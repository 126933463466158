

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
// @ts-ignore
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { CustomMultiSelect, StyledSearchbar },
})
export default class CountryFilter extends Vue {
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;

    private query: string | null = null;

    get items() {
        const { countriesList } = this.eventsFilterService.settings;
        let items = Object.entries(countriesList).map(([key, val]) => ({
            value: key,
            name: val,
            disabled: false,
        }));

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            items = items.filter(type => searchPattern.test(type.name.toLowerCase()));
        }

        if (!items.length) {
            items.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return items;
    }

    get countries(): Item[] {
        const { countries } = this.eventsFilterService.settings;

        return countries.map((value): Item => ({
            value,
            name: this.eventsFilterService.settings.countriesList[value] || value,
        }));
    }

    set countries(items: Item[]) {
        this.eventsFilterService.settings = {
            ...this.eventsFilterService.settings,
            countries: items.map(item => item.value as string),
        };
    }

    handleChange(query: string) {
        this.query = query;
    }
}
