

























import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import { $enum } from 'ts-enum-util';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';

@Component({
    components: { CustomMultiSelect, StyledSearchbar, EventTypeIcon },
})
export default class EventTypesFilter extends Vue {
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;

    private query: string | null = null;

    get items() {
        let items = $enum(EVENT_TYPE_SETTINGS).getValues().map((value): Item => ({
            value,
            name: this.$t(`eventsSettings.${value}`).toString().toUpperCase(),
            disabled: false,
        }));

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            items = items.filter(type => searchPattern.test(type.name.toLowerCase()));
        }

        if (!items.length) {
            items.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return items;
    }

    get eventTypes(): Item [] {
        return this.eventsFilterService.settings.types.map((value): Item => ({
            value,
            name: this.$t(`eventsSettings.${value}`).toString().toUpperCase(),
        }));
    }

    set eventTypes(items: Item[]) {
        this.eventsFilterService.settings = { ...this.eventsFilterService.settings, types: items.map(item => item.value as EVENT_TYPE_SETTINGS) };
    }

    handleChange(query: string) {
        this.query = query;
    }
}
