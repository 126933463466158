

































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import Month from '@/modules/common/types/month.type';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import EventsCard from '@/modules/events/components/events-manager/events-card.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';

@Component({
    components: {
        LoaderWrapper,
        Calendar,
        EventsCard,
        CustomLoader,
    },
})
export default class EventsCalendar extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    private currentMonth = -1;

    get year(): number {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month(): number {
        return this.documentFiltersService.storeState.settings.month;
    }

    get skeleton() {
        return this.eventsManagerService.isLoading;
    }

    getDayEvents(day: Day): IDayEvent[] {
        const updateFilters = this.currentMonth !== this.month;
        const dayEvent = this.eventsManagerService.getEventsByDay({ day, month: this.month as Month }, undefined, updateFilters);
        this.currentMonth = dayEvent.isUpdated ? this.month : this.currentMonth;
        return dayEvent.dayEvents;
    }

    handleCalendarItemClick(day: Day) {
        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.day-event-modal`
            : 'events-manager.day-event-modal';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                day: String(day),
            },
        });
    }

    viewEvent(e: Event, eventId: string) {
        e.stopPropagation();

        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.view`
            : 'events-manager.view';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                eventId,
            },
        });
    }

    hiddenEvents(day: Day): number {
        const eventsCount = this.getDayEvents(day).length;
        return eventsCount > 2 ? eventsCount - 2 : 0;
    }
}
