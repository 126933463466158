





















import Flag from '@/modules/common/components/ui-kit/flag.vue';
import CapitalizeFirst from '@/modules/common/filters/CapitalizeFirst';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import EventsModel from '@/modules/events/models/events.model';

@Component({
    components: {
        EventTypeIcon,
        Flag,
    },
    filters: {
        CapitalizeFirst,
    },
})
export default class EventsCard extends Vue {
    @Prop({ required: true, type: Object })
    private event!: EventsModel;

    @Prop({ required: false, type: Boolean })
    private isHoliday: boolean | undefined;

    @Prop({ required: false, type: Boolean })
    private isShort: boolean | undefined;

    @Prop({ required: false, type: Boolean })
    private isMy: boolean | undefined;

    @Prop({ required: false, type: Boolean })
    private isSuggested!: boolean | undefined;

    handleClick() {
        this.$emit('selectEvent', this.event);
    }
}
